.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  padding-bottom: 1rem;

  .help-desk-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      svg {
        fill: #000000;
      }
      span {
        color: #000000;
      }
    }
  }
}

.footer-menu-container {
  position: absolute;
  bottom: 0;
  right: 100%;
  width: max-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.375rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

  .menu-item {
    padding: 0.375rem 0.75rem;
    width: 100%;
    text-align: left;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      background-color: #eeeeee;

      span {
        color: #929292;
      }
    }
  }
  .menu-item:nth-child(1) {
    padding: 0.75rem 0.75rem 0.375rem 0.75rem;
    border-radius: 0.375rem 0.375rem 0 0;
  }
  .menu-item:nth-child(1):only-child {
    padding: 0.75rem;
    border-radius: 0.375rem;
  }
  .menu-item:last-child {
    padding: 0.375rem 0.75rem 0.75rem 0.75rem;
    border-radius: 0 0 0.375rem 0.375rem;
  }
}

.footer-cta-menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  background-color: white;

  .menu-button-icon {
    fill: #1460e7;
  }

  &:hover {
    .menu-button-icon {
      fill: #21438b;
    }
  }

  &:active {
    .menu-button-icon {
      fill: #5d8cec;
    }
  }
}

.payout-amount-text {
  font-weight: 600 !important;
}

.payout-amount-text-small {
  font-weight: 500 !important;
}

// this retains background color when autofilled with browser
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #e4edff inset !important;
}

.progen-card:focus-within {
  border: 0 !important;
}
.tooltip {
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
  z-index: -1;
  text-align: left;
}

.tooltip::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #333;
  top: 99%;
  left: 70%;
  transform: translateX(-50%);
}
.tooltip-icon:hover + .tooltip {
  opacity: 1;
  z-index: 10;
}

.progen-card {
  border: none !important;
  width: 100% !important;
  max-width: none !important;
  .react-select__menu {
    z-index: 2;
  }

  .request-term-dropdown {
    .react-select__menu-list {
      max-height: 5.25rem;
    }
  }

  .state-dropdown {
    .react-select__menu-list {
      max-height: 8.95rem;
    }
  }

  .subsidy-input {
    ::placeholder {
      color: #929292;
    }
  }
  .empty-subsidy {
    #prefix {
      color: #929292;
    }
  }
  .Card-root {
    min-width: 25.125rem;
    border-radius: 0.5rem;
    border: none;
    padding-bottom: 0.625rem;
    position: relative;
  }
}

.summary-tab {
  #back-to-home-btn {
    width: 4.563rem;
    height: 2.125rem;
    padding: 0.5rem;
    gap: 0.125rem;
    justify-content: start;
  }
  #learn-more-btn {
    width: fit-content;
    align-self: center;
    height: min-content;
  }

  .more-info-step-icon {
    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
    background: rgb(241 245 249);
    z-index: 1;
  }

  .more-info-time-line {
    position: absolute;
    left: 0.813rem;
    opacity: 0.25;
    background: #bcc2cb;
    height: 100%;
    width: 0.125rem;
  }
}

.vd-customer-track-container,
.vd-order-track-screen-container {
  .progen-v4 {
    background-color: #f4f6f9;
    border-radius: 0.5rem;
    height: 21rem;
    width: 28.125rem;
    position: relative;

    .progen-footer {
      background-color: #ffffff;
      padding: 0.75rem 0;
      box-shadow: 0px -2px 4px -2px rgba(0, 0, 0, 0.05),
        0px -4px 6px -1px rgba(0, 0, 0, 0.05);
      position: absolute;
      bottom: 0;
      min-height: 3.625rem;
    }
  }
}
.vd-customer-track-container {
  .progen-v4 {
    height: 24.625rem;
    width: 28.125rem;
    .summary-tab {
      height: 21rem;
      width: 28.125rem;
    }
  }
}
.order-summary {
  height: calc(100% - 3.125rem);

  .description {
    // For cases where multiple descriptions are already present, we dont want to truncate the text
    span:not(:first-child):not(.bullets) {
      white-space: initial;
    }

    .bullets {
      margin-top: 0.25rem;
    }
  }
}

.order-footer {
  box-shadow: 0px -2px 4px -2px rgba(0, 0, 0, 0.05), 0px -4px 6px -1px rgba(0, 0, 0, 0.05);
}

.vd-order-track-container > div {
  width: 100%;
  padding-bottom: 0.75rem;
}

.progen-v4 {
  #progen-cta {
    padding: 1rem;
    width: auto;
    border-radius: 4px;

    &.loading {
      padding: 0.75rem;
    }
  }
}

div.hubspot-widget-container {
  width: 31.25rem;
  max-width: 31.25rem;
}

.spiff-field.is-error {
  input {
    background: none;
  }
}

.footer-buttons-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  flex-grow: 1;
}

// style props for crm widget instance
.crm-widget-container {
  max-width: 28.125rem;
  min-width: 17.5rem;

  .react-select__control {
    background: transparent !important;
  }

  .subsidy-input {
    .react-select__control {
      background: #bcd1f5 !important;
    }
  }

  .header-container {
    padding-top: 0.5rem;

    .help-desk-container {
      gap: 0.125rem;
    }
  }

  .tab-content {
    overflow-x: hidden;
  }

  .tab-footer {
    padding-bottom: 0;
  }

  .order-summary {
    height: calc(100% - 3.875rem);
    padding: 1rem;
  }
  .order-footer {
    padding: 0.75rem 0;
  }

  .footer-menu-container {
    position: absolute;
    bottom: 0;
    right: 100%;
    width: max-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.375rem;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

    .menu-item {
      padding: 0.375rem 0.75rem;
      width: 100%;
      text-align: left;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        background-color: #eeeeee;

        span {
          color: #929292;
        }
      }
    }
    .menu-item:nth-child(1) {
      padding: 0.75rem 0.75rem 0.375rem;
      border-radius: 0.375rem 0.375rem 0 0;
    }
    .menu-item:nth-child(1):only-child {
      padding: 0.75rem;
      border-radius: 0.375rem;
    }
    .menu-item:last-child {
      padding: 0.375rem 0.75rem 0.75rem;
      border-radius: 0 0 0.375rem 0.375rem;
    }
  }

  // this css will only be called when the class occurs in CRM
  .footer-cta-menu-button {
    .menu-button-icon {
      fill: #1460e7;
    }

    &:hover {
      .menu-button-icon {
        fill: #21438b;
      }
    }

    &:active {
      .menu-button-icon {
        fill: #5d8cec;
      }
    }
  }

  // Todo: MuhammadAhmadEjaz replace the tab overflow with a better solution
  div[data-testid='Test_Tab'] {
    width: 100%;
    overflow: auto;
  }

  .tab-content:has(.summary-container) {
    padding: 1rem;
  }

  .tab-content:has(.get-started-container) {
    padding: 0rem 1rem 1rem 1rem;
  }

  .description-typography {
    width: 100%;
  }

  // common styles for width variants sm, md, lg
  &.width-variant-sm,
  &.width-variant-md,
  &.width-variant-lg {
    .payout-amount-text {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 150%;
    }
    .tabItems {
      span {
        font-size: 0.625rem;
        line-height: 1rem;
        font-weight: 500;
      }
    }
    .TabClass {
      padding: 0 1rem;
      .active {
        span {
          font-weight: 600;
        }
      }
    }

    .error-message-container {
      span {
        font-size: 0.625rem;
        font-weight: 500;
        line-height: 1rem;
      }
      svg {
        width: 0.875rem;
        height: 0.875rem;
      }
      > div {
        margin-top: 0.25rem;
      }
    }
    // hide currency pill flag text on compact versions
    .compact-flag-text,
    .default-flag-text {
      display: none;
    }

    .footer-buttons-container {
      gap: 0.5rem;
    }

    .payout-primary-text {
      font-size: 0.5625rem;
      font-weight: 500;
      line-height: 125%;
    }

    .payout-payment-text {
      margin: 0.19rem 0;
    }

    .pg-required-text {
      span {
        font-size: 0.5625rem;
        font-weight: 500;
        line-height: 125%;
      }
    }

    .tertiary-cta-buttons {
      div {
        font-size: 0.625rem;
      }
    }

    .tab-content {
      min-height: 23.525rem;
      scrollbar-width: none;
    }
    .tab-content::-webkit-scrollbar {
      // for Safari
      display: none;
    }
    .tab-content:has(.defer-payment-tab, .installment-payment-tab) {
      padding: 0.75rem 1rem;
      position: relative;
      align-items: flex-start;
    }

    .tab-content:has(.get-started-container) {
      align-items: center;
    }

    .tab-content:has(.customer-form-container) {
      padding: 1.5rem 1rem;
      align-items: flex-start;
    }
    .header-container {
      padding: 0.75rem 1rem 0.625rem 1rem;

      .widget-vartana-logo {
        width: 5.313rem;
        height: 1.063rem;
      }

      span {
        font-size: 0.625rem;
      }
    }

    .calculator-container {
      // calculator field alignment & other styles for width variants sm, md, lg
      .fields-parent-container {
        .fields-upper-container,
        .fields-lower-container {
          display: flex;
          flex-wrap: wrap;
          row-gap: 0.5rem;

          > div:first-child {
            width: 100%; // make the field container 100% width to override the default
            min-width: 100%;
            > div {
              width: 100%; // make the input field 100% width to override the default
            }
          }

          > div:nth-child(2),
          > div:nth-child(3) {
            max-width: calc(100% / 2 - 0.19rem); // 50% width with 0.19rem gap
          }
          > div:nth-child(2):last-child {
            max-width: 100%; // if there's only spiff or subsidy field, we would show it with full width
            > div {
              max-width: 100%;
            }
          }
        }
      }
      .calculator-payout-container {
        .calculator-payout-value {
          span {
            font-size: 0.625rem;
            font-weight: 600;
            line-height: 1rem;
          }
        }
      }
    }
    .city-input-field {
      input {
        text-overflow: ellipsis;
        max-width: max-content;
      }
    }

    #back-to-home-btn {
      width: 3.5rem;
      height: 1.5rem;
      padding: 0.25rem;

      span {
        font-size: 0.5625rem;
        line-height: 1rem;
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    .step-tracker-container {
      padding-top: 2rem;
      padding-left: 2rem;
    }

    .more-info-time-line {
      left: 2.813rem;
    }

    .credit-info {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    .credit-details {
      font-size: 0.5625rem;
      line-height: 0.70313rem;
    }
  }
  // styles for width variant xl
  &.width-variant-xl {
    .tabItems {
      span {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
    .payout-amount-text {
      font-weight: 600;
    }
    .TabClass {
      padding: 0 1.5rem;
    }
    .tab-content {
      min-height: 22.4rem;
    }
    .tab-content:has(.defer-payment-tab, .installment-payment-tab) {
      padding: 0.75rem 1.5rem;
    }
    .header-container {
      padding: 1.25rem 1.5rem 1rem 1.5rem;

      .widget-vartana-logo {
        width: 6.638rem;
        height: 1.249rem;
      }
    }

    .payout-payment-text {
      margin: 0.25rem 0;
    }

    .fields-lower-container {
      .react-select__value-container {
        max-width: 6.739rem; // 6.739rem is the max width for the react-select dropdown, we are limiting width to avoid unwanted overflows
      }
    }
  }

  &.width-variant-sm {
    .installment-tab-container:has(.error-message-container),
    .defer-tab-container:has(.error-message-container) {
      gap: 0.5rem;
    }
  }

  // styles for sm and md breakpoint only
  &.width-variant-sm,
  &.width-variant-md {
    .installment-tab-container,
    .defer-tab-container {
      .pg-tooltip {
        max-width: 11.5rem;
        left: 0;
      }
      .pg-tooltip::after {
        display: none;
      }
    }
    .available-amount {
      font-size: 0.625rem;
      line-height: 1rem;
    }
    .payout-amount-text-small {
      font-size: 0.5625rem;
      font-weight: 600;
      line-height: 125%;
    }
    .pill-container {
      padding: 0.25rem 0.5rem;
      span {
        font-size: 0.5625rem;
        line-height: 125%;
      }
    }
  }

  // styles for sm breakpoint only
  &.width-variant-sm {
    .calculator-payout-container {
      // we should show the amount and subsidy fields in a column for sm width variant
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: initial;
      justify-content: initial;
      align-self: initial;
      > div {
        justify-content: initial;
      }
    }
    .tooltip::after {
      display: none;
    }

    .summary-container {
      overflow-x: hidden;
    }

    .pg-required-text {
      align-items: flex-end;
    }

    .summary-container {
      .pg-required-text {
        align-items: center;
      }
    }
  }

  &.width-variant-lg {
    .payout-amount-text {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.75rem;
    }
    .payout-amount-text-small {
      font-size: 0.625rem;
      font-weight: 600;
      line-height: 1rem;
    }
  }

  .installment-tab-container,
  .defer-tab-container {
    width: 100%;

    .progen-card {
      width: 100%;
      max-width: initial;
      min-width: initial;

      .Card-root {
        width: 100%;
        max-width: initial;
        min-width: initial;
      }
    }
  }

  .subsidy-field {
    .react-select__value-container--has-value {
      margin-bottom: 0.25rem;
    }

    .compact-compound-unit-field {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: fit-content;
    }
  }

  .customer-form-container {
    width: 100%;

    .progen-card {
      width: 100%;
      max-width: initial;
      min-width: initial;

      .Card-root {
        width: 100%;
        max-width: initial;
        min-width: initial;
      }
    }
  }
}

.pre-qualified-banner {
  position: absolute;
  top: -7px;
  right: -8px;
  padding: 3px 10px 4px;
  background-color: #12bdbd;
  color: white;
  font-size: 9px;
  line-height: 125%;
  font-weight: 600;
  font-family: Inter;
  text-align: right;
  border-radius: 4px 4px 0px 4px;
}
.pre-qualified-banner:after {
  width: 8px;
  height: 6px;
  background-color: #10a0a0;
  top: 18px;
  right: 0px;
  clip-path: polygon(100% 0%, 0% 100%, 0% 0%);
  content: '';
  position: absolute;
}
